import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import API from "../../api"
import {Col, Row, Label, Form, Button, Input} from "reactstrap"
// Toastify
import {ToastContainer, toast} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {Slide} from "react-toastify"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import {withTranslation} from "react-i18next"

const AddCalExam = props => {
    const [disbutReg, setDisbutReg] = useState(true)
    const [session, setSession] = useState([])
    const [selectSession, setSelectSession] = useState("")
    const [selectEnseignant, setSelectEnseignant] = useState("")
    const [matiere, setMatiere] = useState([])
    const [selectMatiere, setSelectMatiere] = useState()
    const [date, setDate] = useState(new Date())
    const [semestre, setSemestre] = useState([
        {value: 1, label: "Semestre 1"},
        {value: 2, label: "Semestre 2"},
    ])
    const [enseignant, setEnseignant] = useState([])
    const [quart, setQuart] = useState([])
    const [selectSemestre, setSelectSemestre] = useState("")
    const [selectCycle, setSelectCycle] = useState("")
    const [selectQuart, setSelectQuart] = useState("")
    const [selectSpecialite, setSelectSpecialite] = useState("")
    const [selectNiveau, setSelectNiveau] = useState("")
    const [niveau, setNiveau] = useState([])
    const [specialite, setSpecialite] = useState([])
    const [cycle, setCycle] = useState([])
    const [salle, setSalle] = useState([]);
    const [selectSalle, setSelectSalle] = useState("");
    const [groupe, setGroupe] = useState([]);
    const [selectGroupe, setSelectGroupe] = useState("");
    const [file, setFile] = useState(null)
    var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
    var annee_id = userAuthScolarite.user.annee_id
    useEffect(async () => {
        // Matiere
        API.get("matiere/select").then(res => {
            setMatiere(res.data.Matieres)
        })
        // cycle
        API.get("cycle/select").then(res => {
            setCycle(res.data.Cycle)
        })
        //niveau
        API.get("niveau/select").then(resNiveau => {
            setNiveau(resNiveau.data.Niveau)
        })
        //ssssion
        API.get("session/select").then(resS => {
            setSession(resS.data.Session)
        })
        // enseignant
        API.get("enseignant/select").then(resE => {
            setEnseignant(resE.data.select_enseignant)
        })
        // salle
        API.get("salle/select").then(resSalle => {
            setSalle(resSalle.data.Salle)
        })
        // quartExamen
        const resExamen = await API.post("quart/examen/list", {annee_id}).then(resExamen => {
            setQuart(resExamen.data.QuartExamen)
        })
    }, [])

    const getSpecialite = async event => {
        setSelectSemestre(event)
        const res = await API.post("specialite/get_by_niveaux_cycle_semestre", {
            semestre_id: event.value,
            niveau_id: selectNiveau.value,
            cycle_id: selectCycle.value,
        }).then(res => {
            setSpecialite(res.data.Specialites)
        })
    }

    const getMatiere = async event => {
        setSelectSpecialite(event)
        const res = await API.post(
            "matiere/get_by_cycle_niveaux_semestre_specialite",
            {
                specialite_id: event.value,
                semestre_id: selectSemestre.value,
                niveau_id: selectNiveau.value,
                cycle_id: selectCycle.value,
            }
        ).then(res => {
            setMatiere(res.data.Matiere)
        })
        API.post("group/get_by_cyc_niv_sem_spec", {
            annee_id: annee_id,
            cycle_id: selectCycle.value,
            niveau_id: selectNiveau.value,
            semestre_id: selectSemestre.value,
            specialite_id: event.value,
        }).then(resGroup => {
            setGroupe(resGroup.data.groups)
        })
    }
    const addFile = e => {
        var file = e.target.files[0]
        setFile(file)
    }
    const save = async () => {
        var etat = true
        if (file != null) {
            pathFile = file.name
        }
        if (selectCycle == "") {
            var etat = false
            toast.error("⛔ Cycle obligatoire", {
                containerId: "A",
            })
        } else if (selectNiveau == "") {
            var etat = false
            toast.error("⛔ Niveau obligatoire", {
                containerId: "A",
            })
        } else if (selectSemestre == "") {
            var etat = false
            toast.error("⛔ Semestre obligatoire", {
                containerId: "A",
            })
        } else if (selectSpecialite == "") {
            var etat = false
            toast.error("⛔ Specialité obligatoire", {
                containerId: "A",
            })
        } else if (selectMatiere == "") {
            var etat = false
            toast.error("⛔ Matière obligatoire", {
                containerId: "A",
            })
        } else if (selectSession == "") {
            var etat = false
            toast.error("⛔ Session obligatoire", {
                containerId: "A",
            })
        } else if (selectQuart == "") {
            var etat = false
            toast.error("⛔ Heure examen obligatoire", {
                containerId: "A",
            })
        } else if (selectEnseignant == "") {
            var etat = false
            toast.error("⛔ Enseignant responsable obligatoire", {
                containerId: "A",
            })
        } else if (selectSalle == "") {
            var etat = false
            toast.error("⛔ Salle obligatoire", {
                containerId: "A",
            })
        } else if (selectGroupe == "") {
            var etat = false
            toast.error("⛔ Groupe obligatoire", {
                containerId: "A",
            })
        } else if (file == null) {
            var etat = false
            toast.error("⛔ Fichier obligatoire", {
                containerId: "A",
            })
        }
        if (etat) {
            let convert = date
            let month = "" + (convert.getMonth() + 1)
            let day = "" + convert.getDate()
            let year = convert.getFullYear()
            if (month.length < 2) month = "0" + month
            if (day.length < 2) day = "0" + day
            let convertDate = [year, month, day].join("-")
            setDisbutReg(false)
            //Test si file vide
            var pathFile = ""
            if (file != null) {
                pathFile = file.name
            }
            if (pathFile != "") {
                const formData = new FormData()
                formData.append("document", file)
                formData.append("cycle_id", selectCycle.value)
                formData.append("niveau_id", selectNiveau.value)
                formData.append("specialite_id", selectSpecialite.value)
                formData.append("semestre_id", selectSemestre.value)
                formData.append("matiere_id", selectMatiere.value)
                formData.append("annee_id", annee_id)
                formData.append("session_id", selectSession.value)
                formData.append("date", convertDate)
                formData.append("enseignant_id", selectEnseignant.value)
                formData.append("quart_examen_id", selectQuart.value)
                formData.append("salle_id", selectSalle.value)
                formData.append("group_id", selectGroupe.value)
                API.post("examen/final/add0", formData).then(res => {
                    props.setSection(1)
                })
            }

        }
    }
    return (
        <React.Fragment>
            <Row>
                <Form>
                    <Row>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Cycle</Label>
                                <Select
                                    options={cycle}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectCycle(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Niveau</Label>
                                <Select
                                    options={niveau}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectNiveau(e)}
                                />
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Semestre:</Label>
                                <Select
                                    options={semestre}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => getSpecialite(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">Spécialité</Label>
                                <Select
                                    options={specialite}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => getMatiere(e)}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Matière")}
                                </Label>
                                <Select
                                    options={matiere}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectMatiere(e)}
                                    placeholder={props.t("Select")}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Session")}
                                </Label>
                                <Select
                                    options={session}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectSession(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Date")} :
                                </Label>
                                <DatePicker
                                    selected={date}
                                    className="form-control ddate"
                                    dateFormat="dd/MM/yyyy"
                                    onChange={e => setDate(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Heure Examen")}
                                </Label>
                                <Select
                                    options={quart}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectQuart(e)}
                                    placeholder={props.t("Select")}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Enseignants responsable")}
                                </Label>
                                <Select
                                    options={enseignant}
                                    isSearchable={true}
                                    isClearable={true}
                                    // isMulti={true}
                                    onChange={e => setSelectEnseignant(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Salle")}
                                </Label>
                                <Select
                                    options={salle}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectSalle(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">
                                    {props.t("Groupe")}
                                </Label>
                                <Select
                                    options={groupe}
                                    isSearchable={true}
                                    isClearable={true}
                                    onChange={e => setSelectGroupe(e)}
                                />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className="mb-3">
                                <Label for="basicpill-firstname-input1">File :</Label>
                                <Input
                                    onChange={e => addFile(e)}
                                    className="form-control"
                                    type="file"
                                    id="formFile"
                                    accept=".xls, .xlsx"
                                />
                            </div>
                        </Col>
                    </Row>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        {" "}
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                                className="text-center mt-4"
                            >
                                <Button
                                    type="button"
                                    color="warning"
                                    className="btn btn-warning  mb-2 me-2"
                                    onClick={props.back}
                                >
                                    {props.t("Annuler")}
                                </Button>
                            </div>
                        </Col>
                        <Col lg="6">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                }}
                                className="text-center mt-4"
                            >
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    disabled={!disbutReg}
                                    onClick={save}
                                >
                                    {props.t("Confirmer")}
                                </button>
                            </div>
                        </Col>
                    </div>
                </Form>
            </Row>
            <ToastContainer
                transition={Slide}
                enableMultiContainer
                containerId={"A"}
                position={toast.POSITION.TOP_RIGHT}
                autoClose={2500}
            />
        </React.Fragment>
    )
}

export default withTranslation()(AddCalExam)
AddCalExam.propTypes = {
    history: PropTypes.object,
    setSection: PropTypes.object,
    back: PropTypes.object,
    t: PropTypes.any,
}
