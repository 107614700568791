import React, {useEffect, useMemo, useState} from "react"
import MetaTags from "react-meta-tags"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Row,
  Spinner,
} from "reactstrap"
import API from "../../api"
import countryList from "react-select-country-list";
import user1 from "../../assets/images/users/userImage.png"
const DetailEtudiant = props => {
  const [id, setId] = useState("")
  const [sexe, setSexe] = useState(1);
  const [date, setDate] = useState("")
  const options = useMemo(() => countryList().getData(), [])
  const [etatCivil, setEtatCivil] = useState(1)
  const [gouvernorat, setGouvernorat] = useState([])
  const [selectGouvernorat, setSelectGouvernorat] = useState("");
  const [email, setEmail] = useState("")
  const [anneeBac, setAnneeBac] = useState("")
  const [section, setSection] = useState([])
  const [selectSection, setSelectSection] = useState("");
  const [mention, setMention] = useState([])
  const [selectMention, setSelectMention] = useState("");
  const [session, setSession] = useState([])
  const [selectSession, setSelectSession] = useState("");
  const [selectCycle, setSelectCycle] = useState("")
  const [selectNiveau, setSelectNiveau] = useState("")
  const [selectSpecialite, setSelectSpecialite] = useState("")
  const [selectGroupe, setSelectGroupe] = useState("")
  const [cin, setCin] = useState("");
  const [numero_inscription, setNumero_inscription] = useState("");
  const [selectTypeInscription, setSelectTypeInscription] = useState("");
  const [derogataire, setDerogataire] = useState(2);
  const [prenom, setPrenom] = useState("")
  const [nom, setNom] = useState("")
  const [rue, setRue] = useState("")
  const [codePostal, setCodePostal] = useState("")
  const [lieuNaissance, setLieuNaissance] = useState("")
  const [selectNationality, setSelectNationality] = useState("")
  const [tel, setTel] = useState("")

  const [photo, setPhoto] = useState("")
  const [etat, setEtat] = useState("")
  const [loading, setLoading] = useState(false)
  useEffect(async () => {
    var url = window.location.href
    var array = url.split("=")
    var id = array[1]
    const res = await API.post("etudiant/get_by_id", {
      id: id,
    }).then(res => {
      API.get("annee/select").then(resAnnee => {
         if (resAnnee.data.status === 200) {
          const resC = API.post("check_profile_image", {
            type: "etudiant",
            id: res.data.etudiant.id,
            annee_id: resAnnee.data.Annee[0].CurrentAnnee[0].value
          }).then(resC => {
            setEtat(resC.data.error)
          })
        }
      })
      setNom(res.data.etudiant.nom)
      setPrenom(res.data.etudiant.prenom)
      setCin(res.data.etudiant.cin)
      setNumero_inscription(res.data.etudiant.numero_inscription)
      setSexe(res.data.etudiant.sexe)
      setDate(res.data.etudiant.dateNaissance)
      setSelectNationality(res.data.etudiant.nationalite.label)
      setLieuNaissance(res.data.etudiant.lieuNaissance)
      setTel(res.data.etudiant.telephone)
      setEmail(res.data.etudiant.email)
      setRue(res.data.etudiant.rue)
      setCodePostal(res.data.etudiant.codePostal)
      setSelectGouvernorat(res.data.gouvernorat)
      setAnneeBac(res.data.etudiant.anneBac)
      setSelectSection(res.data.etudiant.section.label)
      setSelectMention(res.data.etudiant.mention.label)
      setSelectSession(res.data.etudiant.session.label)
      setSelectCycle(res.data.etudiant.cycle.label)
      setSelectNiveau(res.data.etudiant.niveau.label)
      setSelectSpecialite(res.data.etudiant.specialite.label)
      setSelectGroupe(res.data.etudiant.group_td.label)
      setDerogataire(res.data.etudiant.derogataire)
      setSelectTypeInscription(res.data.etudiant.type_inscription.label)
      setId(res.data.etudiant.id)
      // setPhoto(res.data.etudiant.photo)
      setLoading(true)

    })
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Consultation Etudiant | Cresus </title>
        </MetaTags>
        {loading ? (
          <Container fluid={true}>
            <Row>
              <Col className="pt-4" xl="6">
                <Card className="overflow-hidden">
                  <CardBody className="pt-4">
                    <Row>
                      <Col sm="4">
                        <div
                          style={{ marginTop: "5%" }}
                          className="avatar-md profile-user-wid mb-4"
                        >
                          {etat != 0 ? (
                              <img
                                  style={{ width: "100%" }}
                                  src={
                                      "https://flshs.scolarite.backcresus-institut.ovh/api/assetsmanager/renseignement/" +id+"/"+selectAnnee
                                  }
                              />
                          ) : (
                              <img style={{ width: "100%" }} src={user1} />
                          )}
                          {/*<img*/}
                          {/*  src={photo}*/}
                          {/*  alt=""*/}
                          {/*  className="img-thumbnail rounded-circle"*/}
                          {/*/>*/}
                        </div>
                      </Col>
                      <Col sm="8">
                        <div>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <p className="text-muted mb-0"> </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-home mb-2 me-2"></i>
                              <p className="text-muted mb-0">
                                {rue}
                              </p>
                              {/*<i*/}
                              {/*  style={{ marginLeft: "1vw" }}*/}
                              {/*  className="fas fa-city	 mb-2 me-2"*/}
                              {/*></i>*/}
                              {/*<p className="text-muted mb-0">{addressState}</p>*/}
                            </Col>
                          </Row>

                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-phone mb-2 me-2"></i>
                              <p className="text-muted mb-0">{tel}</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{ display: "flex" }} xs="12">
                              <i className="fas fa-at mb-2 me-2"></i>
                              <p className="text-muted mb-0">{email}</p>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Nom
                            </td>
                            <td>{nom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-user mb-2 me-2"></i>
                              Prénom
                            </td>
                            <td>{prenom}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-calendar-alt -bill mb-2 me-2"></i>
                              Date de Naissance
                            </td>
                            <td>{date}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-map-marker -bill mb-2 me-2"></i>
                              Lieu de Naissance
                            </td>
                            <td>{lieuNaissance}</td>
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          {sexe === 1 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-male -bill mb-2 me-2"></i>
                                Sexe
                              </td>
                              <td> Masculin</td>
                            </tr>
                          ) : (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-female -bill mb-2 me-2"></i>
                                Sexe
                              </td>
                              <td>Féminin</td>
                            </tr>
                          )}
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-flag -bill mb-2 me-2"></i>
                              Nationnalité
                            </td>
                            <td>{selectNationality}</td>
                          </tr>
                          {etatCivil == 1 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Célibataire </td>
                            </tr>
                          ) : etatCivil == 2 ? (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Marié </td>
                            </tr>
                          ) : (
                            <tr>
                              <td style={{ fontWeight: "bold", width: "20vw" }}>
                                <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                                État Civil
                              </td>
                              <td> Divorcé </td>
                            </tr>
                          )}
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-id-card -bill-wave mb-2 me-2"></i>
                              Numéro
                            </td>
                            <td>{cin}</td>
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Row>
            <Row>
              <Card>
                <CardBody>
                  <Row>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>

                        <tr>
                          <td style={{ fontWeight: "bold", width: "20vw" }}>
                            <i className="fas fa-book-reader -bill mb-2 me-2"></i>
                            Session Bac
                          </td>
                          <td>{selectSession}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold", width: "20vw" }}>
                            <i className="fas fa-check-square  mb-2 me-2"></i>
                            Mention Bac
                          </td>
                          <td>{selectMention}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold", width: "20vw" }}>
                            <i className="fas fa-check-square  mb-2 me-2"></i>
                            Niveau
                          </td>
                          <td>{selectNiveau}</td>
                        </tr>
                        <tr>
                          <td style={{ fontWeight: "bold", width: "20vw" }}>
                            <i className="fas fa-check-square  mb-2 me-2"></i>
                            Spécialité
                          </td>
                          <td>{selectSpecialite}</td>
                        </tr>
                        </tbody>
                      </Table>
                    </Col>
                    <Col lg="6" className="pt-4">
                      <Table>
                        <tbody>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-chalkboard-teacher mb-2 me-2"></i>
                              Section Bac
                            </td>
                            <td>{selectSection}</td>
                          </tr>
                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-chalkboard-teacher mb-2 me-2"></i>
                              Cycle
                            </td>
                            <td>{selectCycle}</td>
                          </tr>


                          <tr>
                            <td style={{ fontWeight: "bold", width: "20vw" }}>
                              <i className="fas fa-users -bill mb-2 me-2"></i>
                              Groupe
                            </td>
                            <td>{selectGroupe}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "felx-start",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Button
                          type="button"
                          color="success"
                          className="btn btn-success mb-2 me-2"
                          onClick={() =>
                            props.history.push("/EditEtudiant?id=" + id)
                          }
                        >
                          Modifier
                        </Button>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        className="text-center mt-4"
                      >
                        <Link to="/Etudiant">
                          <Button
                            type="button"
                            color="warning"
                            className="btn btn-warning mb-2 me-2 "
                          >
                            Annuler
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Row>
          </Container>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {"Chargement"} ...{" "}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default DetailEtudiant

DetailEtudiant.propTypes = {
  history: PropTypes.object,
}
