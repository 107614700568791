import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {  Row,  Col,  Spinner,CardTitle, Form, Label, Table,} from "reactstrap"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import Select from "react-select";
import API from "../../api";

const DataTableAbsence = props => {
  const lng = localStorage.getItem("I18N_LANGUAGE")
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  var user_id = userAuthScolarite.user.id
  const [type, setType] = useState([])
  const [selectType, setSelectType] = useState([])
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const [matiere, setMatiere] = useState([])
  const [selectMatiere, setSelectMatiere] = useState("")
  const [groupe, setGroupe] = useState([])
  const [selectGroupe, setSelectGroupe] = useState("")
  const [loading, setLoading] = useState(true)
  const [date, setDate] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const [etudiantList, setEtudiantList] = useState([])
  const [enseignant, setEnseignant] = useState([]);
  const [selectEnseignant, setSelectEnseignant] = useState("");
  const getMatiere = async (e) => {
    setSelectType(e)
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await API.post("emploi/get_matiere", {
      type: e.value,
      semestre_id: selectSemestre.value,
      enseignant_id: selectEnseignant.value,
      annee_id:annee_id
    }).then(res => {
      setMatiere(res.data.matieres)
    })
  }
  const getType = async (e) => {
    setSelectSemestre(e)
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    const res = await API.post("emploi/get_type_matiere", {
      annee_id:annee_id,
      enseignant_id: selectEnseignant.value,
      semestre_id: e.value,
    }).then(res => {
      setType(res.data.emploi)
    })
  }
  const getGroup = async (e) => {
    setSelectMatiere(e)
    setSelectGroupe("")
    const res = await API.post("registre/get_group_by_enseignant_id", {
      enseignant_id: selectEnseignant.value,
      matiere_id:e.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      semestre_id: selectSemestre.value,
    }).then(res => {
      setGroupe(res.data.Group)
    })
  }
  const getDate = async (e) => {
    setSelectGroupe(e)
    setSelectDate("")
    const res = await API.post("registreGroup/get_date", {
      enseignant_id: selectEnseignant.value,
      matiere_id:selectMatiere.value,
      type_matiere:selectType.value,
      annee_id:annee_id,
      group_id:e.value
    }).then(res => {
      setDate(res.data.dates)
    })
  }

  useEffect(async () => {
    const resE = await API.post("registreGroup/get", {
      annee_id:annee_id
    }).then(resE => {
      setEtudiantList(resE.data.registres)
      setLoading(true)
    })
     API.get("enseignant/select").then(resEns => {
      setEnseignant(resEns.data.select_enseignant)
    })
  }, [])
  const search = async () => {
    const semestre_id = selectSemestre != "" && selectSemestre != null ? selectSemestre.value : 0;
    const enseignant_id = selectEnseignant != "" && selectEnseignant != null ? selectEnseignant.value : 0;
    const type_matiere = selectType != "" && selectType != null ? selectType.value : 0;
    const matiere_id = selectMatiere != "" && selectMatiere != null ? selectMatiere.value : 0;
    const group_id = selectGroupe != "" && selectGroupe != null ? selectGroupe.value : 0;
    const date = selectDate != "" && selectDate != null ? selectDate.label : 0;

    setEtudiantList([])
    const res = await API.post("registreGroup/search", {
      type_matiere: type_matiere,
      semestre_id: semestre_id,
      matiere_id: matiere_id,
      group_id: group_id,
      date: date,
      enseignant_id:enseignant_id,
      annee_id
    }).then(res => {
      setEtudiantList(res.data.registres)
    })
  }
  const searchAll = async () => {
    API.post("registreGroup/get", {
      annee_id:annee_id
    }).then(resE => {
      setEtudiantList(resE.data.registres)
      setLoading(true)
    })
  }
  const getVider = async (e) => {
    setSelectEnseignant(e)
    setSelectSemestre("")
    setSelectType("")
    setSelectMatiere("")
    setSelectGroupe("")
    setSelectDate("")
    setEtudiantList([])
  }
  return (
    <React.Fragment>
      <div>
        <CardTitle style={{ color: "#556ee6" }} className="h4">
          Critéres de Recherches
        </CardTitle>
        <Form className="mt-4">
          <Row>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  {props.t("Enseignant")}
                </Label>
                <Select
                    options={enseignant}
                    isSearchable={true}
                    onChange={e => getVider(e)}
                    value={selectEnseignant}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Semestre
                </Label>
                <Select
                  options={semestre}
                  isSearchable={true}
                  onChange={e => getType(e)}
                />
              </div>
            </Col>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Type</Label>
                <Select
                  options={type}
                  isSearchable={true}
                  value={selectType}
                  onChange={e => getMatiere(e)}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Matiere</Label>
                <Select
                  options={matiere}
                  isSearchable={true}
                  value={selectMatiere}
                  onChange={e => getGroup(e)}
                />
              </div>
            </Col>
            <Col lg="3">
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">Groupe</Label>
                <Select
                  options={groupe}
                  isSearchable={true}
                  value={selectGroupe}
                  onChange={e => getDate(e)}
                />
              </div>
            </Col>
            <Col md={3}>
              <div className="mb-3">
                <Label for="basicpill-firstname-input1">
                  Date
                </Label>
                <Select
                  options={date}
                  isSearchable={true}
                  onChange={e => setSelectDate(e)}
                  value={selectDate}
                />
              </div>
            </Col>
          </Row>
          <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            {" "}
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                  type="button"
                  style={{
                    backgroundColor: "#761C19",
                    borderColor: "#761C19",
                  }}
                  className="btn btn-success mb-2 me-2"
                  onClick={search}
                >
                  Rechercher
                </button>
              </div>
            </Col>
            <Col lg="6">
              <div className="text-center mt-4">
                <button
                  type="button"
                  style={{
                    backgroundColor: "#761C19",
                    borderColor: "#761C19",
                  }}
                  className="btn btn-success mb-2 me-2"
                  onClick={searchAll}
                >
                  Afficher tout
                </button>
              </div>
            </Col>
          </div>
        </Form>
        {loading ? (
          <Row>
            <Col xs="12">
              <Table className="mt-3" striped bordered hover>
                <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Enseignant</th>
                  <th style={{ textAlign: "center" }}>Matière</th>
                  <th style={{ textAlign: "center" }}>Groupe</th>
                  <th style={{ textAlign: "center" }}>Date</th>
                </tr>
                </thead>
                <tbody>
                {etudiantList.map((el, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}> {el.enseignant} </td>
                    <td style={{ textAlign: "center" }}> {el.matiere} </td>
                    <td style={{ textAlign: "center" }}> {el.group} </td>
                    <td style={{ textAlign: "center" }}> {el.date} </td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              <Spinner type="grow" className="ms-6" color="primary" />
            </div>
            <h4
              style={{ textAlign: "center", marginTop: "2%" }}
              className="ms-6"
            >
              {" "}
              {props.t("Chargement en cours...")}
            </h4>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(DataTableAbsence))
DataTableAbsence.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
