import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { Container, Card, CardBody, Nav, NavItem, NavLink } from "reactstrap"
import classnames from "classnames"
import { withRouter } from "react-router"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import DataTableRegistre from "./DataTableRegistre";
import DataTableAbsence from "./DataTableAbsence";

const Resigtre = props => {
  const [section, setSection] = useState(1)

  const renderBlock = section => {
    if (section === 1) {
      return <DataTableRegistre />
    }else if(section === 2) {
      return <DataTableAbsence/>
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> {props.t("Registre")}</title>
        </MetaTags>
        <Container fluid>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 1,
                  })}
                  onClick={() => {
                    setSection(1)
                  }}
                >
                  {props.t("Registre")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: section === 2,
                  })}
                  onClick={() => {
                    setSection(2)
                  }}
                >
                  {props.t("Absence groupe")}
                </NavLink>
              </NavItem>
            </Nav>
            <Card>
              <CardBody>{renderBlock(section)}</CardBody>
            </Card>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(withTranslation()(Resigtre))
Resigtre.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}
