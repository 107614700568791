import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import API from "../../api"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import {Row, Col, Spinner, Form, Label, Button, Modal, ModalBody, Input, Progress} from "reactstrap"
import { withRouter } from "react-router"
import { Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import Select from "react-select";
import {Slide, toast, ToastContainer} from "react-toastify";
import FileDownload from "js-file-download";

const FicheVoeauxList = props => {
  const [loading, setLoading] = useState(false)
  // Data Table
  const [orders, setOrders] = useState([])

  const pageOptions = {
    loading: true,
    hidePageListOnlyOnePage: true,
    totalSize: orders.length,
    page: 1,
    nextPageText: "Suivant",
    prePageText: "Précédent",
    alwaysShowAllBtns: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: orders.length,
      },
    ],
  }
  const [semestre, setSemestre] = useState([
    { value: 1, label: "Semestre 1" },
    { value: 2, label: "Semestre 2" },
  ])
  const [selectSemestre, setSelectSemestre] = useState("")
  const NoDataIndication = () => (
    <h6 style={{ textAlign: "center" }}>No record to display</h6>
  )
  const Columns = () => [
    {
      dataField: "departement",
      text: props.t("département"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "enseignant",
      text: props.t("Chef département"),
      sort: true,
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
    },
    {
      dataField: "Fiche Voeaux",
      isDummyField: true,
      text: props.t("Fiche Voeaux"),
      style: { textAlign: "center" },
      headerStyle: (colum, colIndex) => {
        return { textAlign: "center" }
      },
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Link to={"/FicheVoeuxEns?id=" + row.enseignant_id}>
            <i
              style={{ color: "cornflowerblue", cursor: "pointer" }}
              className="fas fa-eye"
            ></i>
          </Link>
        </div>
      ),
    },
  ]

  const { SearchBar } = Search
  var userAuthScolarite = JSON.parse(localStorage.getItem("userAuthScolarite"))
  var annee_id = userAuthScolarite.user.annee_id
  useEffect(async () => {

    const res = await API.post("departement/ensignant/list",{
      annee_id:annee_id
    }).then(res => {
      setOrders(res.data.Departement)
    })
    setLoading(true)
  }, [])
  const ExportExcel = async () => {
    const res = await API.post(`enseignant/fiche_voeu_export`,{annee_id,semestre_id:selectSemestre.value}, {responseType: "blob"}).then(res => {
      FileDownload(res.data, "Export fiche de voeaux.xlsx")
    })
  }
  return (
      <React.Fragment>
        <div>
          <Row>
            <Col xs="12">
              <ToolkitProvider
                  keyField="id"
                  data={orders}
                  columns={Columns()}
                  search
                  bootstrap4
              >
                {toolkitProps => (
                    <div>
                      <Form className="mt-4">

                        <Row>
                          <Col lg="6">
                            <div className="mb-3">
                              <Label for="basicpill-firstname-input1">Semestre : </Label>
                              <Select
                                  options={semestre}
                                  isSearchable={true}
                                  onChange={e => setSelectSemestre(e)}
                              />
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <Row className="mb-2">
                        <Col sm="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon"/>
                            </div>
                          </div>
                        </Col>
                        {selectSemestre.value !== undefined ? (
                        <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                            className="col-md-6 mb-3 row"
                        >
                          <div
                              style={{textAlign: "right"}}
                              className="col-md-8 select-action"
                          >
                            <a
                                onClick={ExportExcel}

                                target="_blank"
                                rel="noreferrer"
                            >
                              <button className="btn btn-primary btn btn-primary">
                                <i className="fas fa-file-download font-size-16 align-middle me-2"></i>
                                Exportation Fiche Voeaux
                              </button>
                            </a>
                          </div>
                        </div>
                            ):null}
                      </Row>
                      <BootstrapTable
                          wrapperClasses="table-responsive"
                          noDataIndication={() => <NoDataIndication/>}
                          striped={false}
                          bordered={false}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"table-light"}
                          hover
                          pagination={paginationFactory(pageOptions)}
                          {...toolkitProps.baseProps}
                      />
                    </div>
                )}
              </ToolkitProvider>
            </Col>
          </Row>
        </div>
        <ToastContainer
            transition={Slide}
            enableMultiContainer
            containerId={"A"}
            position={toast.POSITION.TOP_RIGHT}
            autoClose={2500}
        />

      </React.Fragment>
  )
}

export default withRouter(withTranslation()(FicheVoeauxList))
FicheVoeauxList.propTypes = {
  orders: PropTypes.array,
  history: PropTypes.object,
  t: PropTypes.any,
}
